export const getPointsNumber = (pathString: string) => {
  const pathDefault = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  pathDefault.setAttribute('d', pathString);
  return Math.ceil(pathDefault.getTotalLength());
};

export const resamplePath = (pathString: string, numPoints: number) => {
  const pathVirtual = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  pathVirtual.setAttribute('d', pathString);
  const totalLength = pathVirtual.getTotalLength();
  const step = totalLength / (numPoints - 1);
  const points = [];

  const round = (value: number) => Number(value.toFixed(1));

  for (let i = 0; i < numPoints; i++) {
    const point = pathVirtual.getPointAtLength(i * step);
    points.push([round(point.x), round(point.y)]);
  }

  const pathSegments = [];

  for (let i = 0; i < points.length; i++) {
    const [x, y] = points[i];
    const command = i === 0 ? 'M' : 'L';
    pathSegments.push(`${command}${x},${y}`);
  }

  if (pathString.trim().endsWith('z') || pathString.trim().endsWith('Z')) {
    pathSegments.push('Z');
  }

  return pathSegments.join(' ').trim();
};

const combineSvgPaths = (dAttributes: Array<string>): string => {
  return dAttributes.map(d => `${d.trim()}`).join(' ');
};

export const getPathFromSvg = (stringSvg: string): string => {
  const dValues = [...stringSvg.matchAll(/<path [^>]*d="([^"]+)"/g)];
  const arrayDValues = dValues.map(dValue => dValue[1]);
  return combineSvgPaths(arrayDValues);
};

export const extractSvgAttributes = (
  svgString: string,
): Record<string, string> => {
  const attributes: Record<string, string> = {};
  const svgTagRegex = /<svg\s+([^>]+)>/i;
  const matchSvgTag = svgString.match(svgTagRegex);

  if (matchSvgTag && matchSvgTag[1]) {
    const attrRegex = /(\w+)=["']([^"']+)["']/g;
    const matches = Array.from(matchSvgTag[1].matchAll(attrRegex));
    for (const match of matches) {
      attributes[match[1]] = match[2];
    }
  }

  return attributes;
};
