import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import type { AnimatedSvgRef, AnimatedSvgProps } from '../AnimatedSvg.types';
import { TestIds } from '../constants';
import { getPointsNumber, resamplePath } from './utils';

const AnimatedSvg = forwardRef<AnimatedSvgRef, AnimatedSvgProps>(
  (
    { pathStart, pathEnd, attributes, duration, reducedMotion = false },
    ref,
  ) => {
    const animateRef = useRef<SVGAnimateElement>(null);
    const animateBackRef = useRef<SVGAnimateElement>(null);
    const [resampleState, setResampleState] = useState({
      resampledPathStart: '',
      resampledPathEnd: '',
    });
    const { resampledPathStart, resampledPathEnd } = resampleState;
    const [isAnimationForwardCompleted, setAnimationForwardCompleted] =
      useState(false);

    useImperativeHandle(ref, () => ({
      runAnimationForward,
      runAnimationBackward,
    }));

    useEffect(() => {
      const pointsNumberStart = getPointsNumber(pathStart);
      const pointsNumberEnd = getPointsNumber(pathEnd);
      const maxPoints = Math.max(pointsNumberStart, pointsNumberEnd);
      const resampledStart = resamplePath(pathStart, maxPoints);
      const resampledEnd = resamplePath(pathEnd, maxPoints);
      setResampleState({
        resampledPathStart: resampledStart,
        resampledPathEnd: resampledEnd,
      });
    }, [pathStart, pathEnd]);

    const runAnimationForward = () => {
      if (animateRef.current && !reducedMotion) {
        animateRef.current.beginElement();
      }
      setAnimationForwardCompleted(true);
    };

    const runAnimationBackward = () => {
      if (animateBackRef.current && !reducedMotion) {
        animateBackRef.current.beginElement();
      }
      setAnimationForwardCompleted(false);
    };

    const handleHover = () => {
      if (isAnimationForwardCompleted) {
        runAnimationBackward();
      } else {
        runAnimationForward();
      }
    };

    return (
      <svg
        {...attributes}
        data-testid={TestIds.animatedSvg}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <g>
          {resampledPathStart && resampledPathEnd ? (
            <path d={isAnimationForwardCompleted ? pathEnd : pathStart}>
              <animate
                ref={animateRef}
                attributeName="d"
                from={resampledPathStart}
                to={resampledPathEnd}
                dur={`${duration}s`}
                begin="indefinite"
                repeatCount="1"
                data-testid={TestIds.animateTagForward}
              />
              <animate
                ref={animateBackRef}
                attributeName="d"
                from={resampledPathEnd}
                to={resampledPathStart}
                dur={`${duration}s`}
                begin="indefinite"
                repeatCount="1"
                data-testid={TestIds.animateTagBackward}
              />
            </path>
          ) : (
            <path d={pathStart} data-testid={TestIds.fallbackPath} />
          )}
        </g>
      </svg>
    );
  },
);

export default memo(AnimatedSvg);
